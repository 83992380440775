import React from 'react';
import { Link, graphql } from 'gatsby';
import * as PropTypes from 'prop-types';
import Button from '../../Button';
import Illustration from '../../Illustration';
import TextUnderline from '../../TextUnderline';
import Image from '../../Image';
import * as styles from './featuredCompanies.module.scss';
import '../../../styles/experimental/illustration-text-stroke.scss';


const FeaturedCompanies = ({ companies }) => {

  return (
    <section className={styles.featuredCompanies}>
      {companies &&
        companies.slice(0, 5).map((company, index) => {
          return (
            <div
              key={index}
              className={[
                isEven(index) ? styles.light : styles.dark,
                styles.featuredCompany,
                'text-stroke-featured-parent',
              ].join(' ')}>
              <div className={styles.inner}>
                <div className={styles.imageryAndContent}>
                  <div className={styles.imagery}>
                    <div className={styles.imageryInner}>
                      <div
                        className={[styles.companyName, 'text-stroke-featured'].join(
                          ' ',
                        )}>
                        {company.name}
                      </div>
                      {company.founderHeadshot && (
                        <Link
                          aria-label={company.founder}
                          to={`/portfolio/${company.slug}`}
                          className={styles.illustration}>
                          <Illustration
                            src={company.founderHeadshot.fluid.src}
                            patternLocation={isEven(index) ? 'left' : 'right'}
                            patternStyle={isEven(index) ? 'light' : 'dark'}
                            hoverEffect={true}
                          />
                        </Link>
                      )}
                    </div>
                  </div>

                  <div className={styles.content}>
                    {isEven(index)
                      ? company.logo &&
                        company.logo.fluid && (
                          <Image
                            className={styles.logo}
                            fluid={company.logo.fluid}
                            alt={`${company.name} logo`}
                          />
                        )
                      : company.logoWhite &&
                        company.logoWhite.fluid && (
                          <Image
                            className={styles.logo}
                            fluid={company.logoWhite.fluid}
                            alt={`${company.name} logo in white`}
                          />
                        )}

                    {company.featuredCompanyHeadline && (
                      <h2 className={styles.headline}>
                        <TextUnderline
                          headline={company.featuredCompanyHeadline}
                          headlineUnderline={company.name}
                          url={`/portfolio/${company.slug}`}
                          underlineTheme={isEven(index) ? 'canvasTan' : 'canvasDarkJean'}
                        />
                      </h2>
                    )}
                    <div className={styles.ctaButton}>
                      <Button
                        theme={isEven(index) ? 'white' : 'jean'}
                        text="Learn More"
                        url={`/portfolio/${company.slug}`}
                      />
                    </div>
                    <Link to={`/portfolio/${company.slug}`} className={styles.ctaLink}>
                      Learn More
                    </Link>
                    {company.thesis && (
                      <dl className={styles.thesis}>
                        <dt className={styles.thesisLabel}>
                          {company.thesis.length > 1 ? 'Theses' : 'Thesis'}
                        </dt>

                        <dd className={styles.thesisNames}>
                          {company.thesis.map((t, i) => (
                            <Link key={i} className={styles.thesisName} to={`/${t.slug}`}>
                              {t.name}
                            </Link>
                          ))}
                        </dd>
                      </dl>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </section>
  );
};

const isEven = number => {
  return number % 2 === 0;
};

FeaturedCompanies.propTypes = {
  index: PropTypes.number,
  companies: PropTypes.array,
};

export default FeaturedCompanies;

export const query = graphql`
  fragment HomepageFeaturedCompanyQuery on ContentfulHomePage {
    featuredCompanies {
      name
      slug
      featuredCompanyHeadline
      founder
      founderTitle
      founderHeadshot {
        fluid {
          src
          srcSet
        }
      }
      thesis {
        name
        slug
      }
      logo {
        fluid {
          src
          srcSet
        }
      }
      logoWhite {
        fluid {
          src
          srcSet
        }
      }
    }
  }
`;
