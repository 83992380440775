import React from 'react';
import * as PropTypes from 'prop-types';

import * as styles from './illustration.module.scss';

const Illustration = ({ src, patternLocation, patternStyle, hoverEffect }) => (
  <div
    className={[
      styles.illustrationWrapper,
      styles[patternLocation],
      styles[patternStyle],
      hoverEffect ? 'zoomOnHover' : '',
    ].join(' ')}>
    <div className={styles.illustrationCrop}>
      <figure className={styles.illustrationBG} style={{ backgroundImage: 'url(' + src + ')' }} />
      <img className={styles.illustration} src={src} alt={""}/>
    </div>
  </div>
);

Illustration.propTypes = {
  src: PropTypes.string,
  patternLocation: PropTypes.string,
  patternStyle: PropTypes.string,
  hoverEffect: PropTypes.bool,
};

export default Illustration;
