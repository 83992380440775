import React from 'react';
import * as PropTypes from 'prop-types';
import * as styles from './founderInfo.module.scss';

const FounderInfo = ({ name, title }) => (
  <p className={styles.founderInfo}>
    <strong>{name}</strong>
    <br />
    {title}
  </p>
);

FounderInfo.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
};

export default FounderInfo;
