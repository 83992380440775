// extracted by mini-css-extract-plugin
export var featuredCompanies = "featuredCompanies-module--featuredCompanies--39lwU";
export var featuredCompany = "featuredCompanies-module--featuredCompany--2qb4U";
export var dark = "featuredCompanies-module--dark--1lNMD";
export var light = "featuredCompanies-module--light--SwGy0";
export var inner = "featuredCompanies-module--inner--3HIQM";
export var imageryAndContent = "featuredCompanies-module--imageryAndContent--1A41X";
export var imagery = "featuredCompanies-module--imagery--3JckP";
export var imageryInner = "featuredCompanies-module--imageryInner--2IRsH";
export var companyName = "featuredCompanies-module--companyName--Xs8fy";
export var illustration = "featuredCompanies-module--illustration--25jHv";
export var content = "featuredCompanies-module--content--bcFGv";
export var logo = "featuredCompanies-module--logo--1QrDr";
export var headline = "featuredCompanies-module--headline--vzuu_";
export var ctaButton = "featuredCompanies-module--ctaButton--1WoLI";
export var ctaLink = "featuredCompanies-module--ctaLink--171r-";
export var thesis = "featuredCompanies-module--thesis--9Ou5h";
export var thesisLabel = "featuredCompanies-module--thesisLabel--2BAjI";
export var thesisNames = "featuredCompanies-module--thesisNames--2yauu";
export var thesisName = "featuredCompanies-module--thesisName--34iu5";