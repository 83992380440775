import React from 'react';
import * as PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import * as styles from './announcement.module.scss';

const Announcement = ({ subTitle, title, description, link, customStyles }) => {
  const card = (
    <div className={styles.card}>
      <div className={styles.tags}>
        {subTitle && <div className={styles.tagText}>{subTitle}</div>}
      </div>
      {title && <h2>{title}</h2>}
      {description && <p>{description.description}</p>}
    </div>
  );

  return (
    <section
      className={
        customStyles ? [styles.customStyles, styles.announcement].join(' ') : styles.announcement
      }>
      <div className={styles.inner}>
        <div className={styles.cardWrapper}>
          {link ? (
            <a href={link} target="_blank" rel="noreferrer">
              {card}
            </a>
          ) : (
            <span>{card}</span>
          )}
        </div>
      </div>
    </section>
  );
};

export default Announcement;

Announcement.propTypes = {
  title: PropTypes.string,
  description: PropTypes.object,
  link: PropTypes.string,
};

export const query = graphql`
  fragment HomepageAnnouncementQuery on ContentfulHomePage {
    showAnnouncement
    announcement {
      subTitle
      title
      description {
        description
      }
      link
    }
  }
`;
