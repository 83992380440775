import React from 'react';
import * as PropTypes from 'prop-types';
import Seo from '../components/SEO';
import Theme from '../../src/components/Theme';
import PageHeader from '../components/PageHeader';
import Announcement from '../components/HomePage/Announcement';
import FeaturedCompanies from '../components/Homepage/FeaturedCompanies';
import MoreFounders from '../components/Homepage/MoreFounders';
import Newsletter from '../components/Newsletter';
import FeaturedTheses from '../components/HomePage/FeaturedTheses';
import FeaturedThoughts from '../components/FeaturedThoughts';
import GeneralLayout from '../layouts/index';
import { graphql } from 'gatsby';

const IndexPage = ({ transition, data }) => {
  const { contentfulHomePage, contentfulNewsletter, site } = data;
  const { showAnnouncement, announcement } = contentfulHomePage;

  return (
    <GeneralLayout>
      <Theme transition={transition} theme={showAnnouncement ? 'emerald' : ''}>
        <Seo
          siteData={site}
          seoData={contentfulHomePage.seo}
          defaultTitle="Emergence"
          pathName="/"
        />
        {showAnnouncement && announcement && (
          <Announcement
            subTitle={announcement.subTitle}
            title={announcement.title}
            description={announcement.description}
            link={announcement.link}
          />
        )}
        <PageHeader
          headline={contentfulHomePage.headline}
          overline={contentfulHomePage.overline}
          headlineUnderline={contentfulHomePage.headlineUnderline}
          url="/portfolio"
          underlineTheme="canvasTan"
          theme="light"
        />
        {contentfulHomePage.featuredCompanies && (
          <FeaturedCompanies companies={contentfulHomePage.featuredCompanies} />
        )}
        {contentfulHomePage.featuredThesesTitle && (
          <FeaturedTheses
            headline={contentfulHomePage.featuredThesesTitle}
            description={contentfulHomePage.featuredThesesDescription}
            button={contentfulHomePage.featuredThesesButtonText}
          />
        )}
        {contentfulHomePage.moreFounders && (
          <MoreFounders companies={contentfulHomePage.moreFounders} />
        )}
        {contentfulHomePage.featuredThoughts && (
          <FeaturedThoughts
            thoughts={contentfulHomePage.featuredThoughts}
            headline="Our Thoughts"
          />
        )}
        <Newsletter content={contentfulNewsletter} />
      </Theme>
    </GeneralLayout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default IndexPage;

export const query = graphql`
  query IndexQuery {
    contentfulHomePage {
      headline
      headlineUnderline
      overline
      ...HomepageAnnouncementQuery
      ...HomepageFeaturedCompanyQuery
      ...HomepageMoreFoundersQuery
      ...HomepageFeaturedThesesQuery
      ...HomepageFeaturedThoughtsQuery
      seo {
        customTitle
        metaDescription
        shareImage {
          file {
            url
          }
        }
      }
    }
    ...NewsletterQuery
    ...SiteQuery
  }
`;
