import React from 'react';
import * as PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import Button from '../../Button';
import FounderInfo from '../FounderInfo';
import Image from '../../Image';
import OutlineHeading from '../../OutlineHeading';
import TextUnderline from '../../TextUnderline';

import * as styles from './moreFounders.module.scss';


const MoreFounders = ({ companies }) => (
  <section className={styles.moreFounders}>
    <div className={styles.inner}>
      <OutlineHeading title="Founders" />
      <ul className={styles.founders}>
        {companies &&
          companies.map((company, index) => {
            return (
              <li key={index} className={styles.founder}>
                <Link className={styles.link} to={`portfolio/${company.slug}`}>
                  {company.showQuoteOnHomePage ? (
                    <div className={'imagery'}>
                      <div className={styles.quote}>
                        <h3 className={styles.quoteText}>
                          <TextUnderline
                            headline={company.quote && company.quote.quote}
                            headlineUnderline={company.quoteUnderline}
                            underlineTheme="canvasMint"
                          />
                        </h3>
                        <img
                          className={styles.spacer}
                          src="/images/illustration-spacer.png"
                          alt="Spacer"
                        />
                      </div>
                      <div className={'info'}>
                        <FounderInfo
                          name={
                            company.quoteAttribution
                              ? company.quoteAttribution
                              : `${company.founder}${
                                  company.founderTitle && `, ${company.founderTitle}`
                                }`
                          }
                          title={company.name}
                        />
                      </div>
                    </div>
                  ) : (
                    company.founderHeadshot && (
                      <figure className={'imagery'}>
                        <Image
                          className={styles.headshot}
                          fluid={company.founderHeadshot.fluid}
                          alt={company.founderHeadshot.title}
                        />
                        <figcaption className={'info'}>
                          <FounderInfo name={company.founder} title={company.name} />
                        </figcaption>
                      </figure>
                    )
                  )}
                </Link>
              </li>
            );
          })}
      </ul>
      <Button text="All Founders" url={`/portfolio`} theme="white" />
    </div>
  </section>
);

MoreFounders.propTypes = {
  companies: PropTypes.array,
};

export default MoreFounders;

export const query = graphql`
  fragment HomepageMoreFoundersQuery on ContentfulHomePage {
    moreFounders {
      name
      slug
      founder
      founderTitle
      founderHeadshot {
        fluid {
          src
          srcSet
        }
      }
      quote {
        quote
      }
      quoteUnderline
      quoteAttribution
      showQuoteOnHomePage
    }
  }
`;
